.county-name {
    font-size: 8px;
    text-anchor: middle;
    pointer-events: none;
}

.county {
    stroke: black;
    fill: white;
}

.county-active {
    fill: green;
}

.county:hover {
    fill: greenyellow;
    transition: 0.2s ease-out;
}
.county-ni {
    fill: lightgray;
}